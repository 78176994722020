@media print {

  header {
    display: none !important;
  }

  .no-print {
    display: none !important;
  }
}
.progress {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2000;
}

.pointable {
  cursor: pointer;
}


.MuiButtonBase-root:hover {
  filter: brightness(0.9) !important;
  transition: all 0.3s;
}